import { useEffect } from 'react'

/**
 * ローカルのscriptを読み込むカスタムフック
 * 今回はプルダウンのリスト読み込みに使用。
 * ローカル,static配下にコピーして読み込む。
 * jQuery依存のためjQueryも内部で読み込ませています。
 * @param {string} scriptSrcPath  srcに入れるパス
 */
/** */
export const useLoadLocalScript = (scriptSrcPath: string) => {
  useEffect(() => {
    // jQuey読み込み
    const scriptTag = document.createElement('script')
    scriptTag.id = 'my_jquery'
    scriptTag.type = 'text/javascript'
    scriptTag.src = '/common/js/jquery-3.6.1.min.js'
    document.body.appendChild(scriptTag)

    // jQuey読み込み完了してから対象のscript読み込み
    scriptTag.onload = () => {
      const newScriptTag = document.createElement('script')
      newScriptTag.id = 'my_script'
      newScriptTag.type = 'text/javascript'
      newScriptTag.src = scriptSrcPath
      document.body.appendChild(newScriptTag)
    }

    return () => {
      const scriptFile = document.getElementById('my_script')
      scriptFile && document.body.removeChild(scriptFile)

      const jQuery = document.getElementById('my_jquery')
      jQuery && document.body.removeChild(jQuery)
    }
  }, [scriptSrcPath])
}
