import React from 'react'
import { LayoutIR } from '../../../components/organisms/Layout/LayoutIR'
import { HeadingTertiary } from '../../../components/atoms/Heading/HeadingTertiary'
import { useLoadLocalScript } from '../../../components/hooks/useLoadLocalScript'
import { styleGoverLink } from '../../../components/styles/governance'
import { SEOIR } from '../../../components/data/SEO'
import { ENGLISH_TITLE } from '../../../components/data/English'

const Page = () => {
  // ローカルのscriptタグを埋めたい場合
  useLoadLocalScript('/common/js/ir/XjStorageLoaderGovernance.js')

  return (
    <LayoutIR
      heading="コーポレート・ガバナンス"
      headingEnglish={ENGLISH_TITLE.CorporateGovernance}
      BreadcrumbData={[
        { title: '株主・投資家の皆さまへ', url: '/ir' },
        { title: '経営方針', url: '/ir/policy' },
        { title: 'コーポレート・ガバナンス', url: '/' },
      ]}
    >
      <div className="pc:pt-12 pc:px-8 pc:mb-12 bg-white leading-tight">
        <HeadingTertiary label="コーポレート・ガバナンスに関する基本的な考え方" />
        <p className="pt-4 pc:pt-8 pb-12 leading-normal">
          当社グループは、私たちに関わる全ての人[クライアント・派遣スタッフ・従業員]を大切にし、人を大切にする世界・誰もが生き生きと働ける社会の実現を目指しており、これを私たちのミッションとして、持続的な顧客の成長と当社グループの成長を実現すべく事業を運営しております。この事業運営に関して、経営の健全性及び透明性を確保し、的確な意思決定・業務執行・監督が機能する経営体制を構築し、企業価値の最大化を目指すことを、コーポレート・ガバナンスの基本方針としております。
        </p>
      </div>

      <div className="pc:pt-12 pc:px-8 pc:mb-12 bg-white leading-tight">
        <HeadingTertiary label="コーポレート・ガバナンス体制図" />
        <img
          className="mt-10 pb-12"
          src="/ir/img/cg.png"
          alt="コーポレート・ガバナンス体制図"
        />
      </div>

      <div className="pc:pt-12 pc:px-8 pc:pb-12 pc:mb-12 bg-white">
        <HeadingTertiary label="コーポレート・ガバナンス報告書" />
        <p className="pt-4 pc:pt-8 pb-4 pc:pb-0 leading-normal">
          当社は東京証券取引所に「コーポレート・ガバナンスに関する報告書」を提出しております。
        </p>
        <div css={styleGoverLink}>
          <p id="xj-mainlist" className="txt-paragraph position-relative"></p>
        </div>
      </div>
    </LayoutIR>
  )
}

export default Page

/**
 * headタグの内容
 */
/** */
export const Head = () => (
  <SEOIR title="コーポレート・ガバナンス" url="/ir/policy/governance" />
)
