import { css } from '@emotion/react'
import { colorList } from './color'

export const styleGoverLink = css`
  #xj-mainlist {
    position: relative;
    display: flex;
    align-items: center;
    padding-top: 8px;
    padding-bottom: 8px;
    &::after {
      content: '';
      background-color: ${colorList.primary};
      width: 9px;
      height: 6px;
      margin-left: 8px;
      clip-path: polygon(0 0, 0% 100%, 100% 50%);
    }
  }
  .txt-link--red {
    max-width: 400px;
    font-size: 16px;
    line-height: 1.7;
    text-decoration: underline;
    margin-right: 16px;
    @media screen and (max-width: 1199px) {
      max-width: 205px;
      margin-right: 16px;
      margin-top: 8px;
    }
    a {
      &:hover {
        color: ${colorList.primary};
        transition: 0.3s;
      }
    }
  }
  .ico-pdf {
    @media screen and (max-width: 1199px) {
      position: static;
      transform: translateY(-50%);
      margin-top: 16px;
    }
    top: 50%;
    right: 53px;
    display: flex;
    align-items: center;
    color: ${colorList.text20};
    font-size: 12px;
    img {
      width: 31px;
      margin-right: 4px;
    }
  }
`
// selectプルダウンのcss
export const selectMenuStyle = css`
  select {
    padding: 20px 0 18px 16px;
    font-size: 16px;
    border: 1px solid ${colorList.border10};
    -webkit-appearance: none;
    appearance: none; /* 矢印hiddeen */
    width: 100%;
    background-image: url('/common/img/arrowPulldown.png');
    background-position: right 0 center;
    background-repeat: no-repeat;
    background-size: 30px 30px;
    @media screen and (max-width: 1199px) {
      width: 212px;
    }
  }
`
