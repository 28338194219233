import React from 'react'
import { css } from '@emotion/react'
import { HeadingFactory } from './HeadingFactory'
import { colorList } from '../../styles/color'

interface Props {
  label: string
  level?: '1' | '2' | '3' | '4' | '5' | '6'
}

const style = css`
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 1px;
    background-color: ${colorList.primary};
  }
`

/**
 * h2での使用を想定。第2階層、第3階層以降で使用。上に赤いバーがある見出し
 * @param {string} label テキストです。
 * @param {'1' | '2' | '3' | '4' | '5' | '6'} level h1,h2,h3...の番号部分。
 */
/** */
export const HeadingTertiary = ({ label, level = '2' }: Props) => {
  return (
    <>
      <HeadingFactory
        css={style}
        level={level}
        className={`text-lg relative leading-normal pt-5 pc:pt-6 pc:text-xl font-bold text-text10`}
      >
        {label}
      </HeadingFactory>
    </>
  )
}
